import React, { useState, useEffect, DOMElement, MouseEvent } from "react"
import CaseList from "../../components/CaseList"
import CategorySelector from "../../components/CategorySelector"
import { useLocation } from "../../lib/use-location"

const CaseOverview = ({ category, tags }) => {
  const [active, setActive] = useState("all")
  const [cases, setCases] = useState([])
  const { push, search } = useLocation()
  const { posts = [] } = category

  useEffect(() => {
    const tag = search.split("=")[1]
    if (tag) {
      setActive(tag)
      const segmentCases = posts?.filter((post) => (tag === "all" ? post : post.tag?.title === tag))

      setCases(segmentCases)
    } else {
      setCases(posts)
    }
  }, [search])

  const handleTagSelect = (e: MouseEvent<HTMLButtonElement>) => {
    const tag = e.target.dataset.tag
    if (tag) {
      setActive(tag)
      push(`?t=${tag}`)
    }
  }
  return (
    <section className="md:flex px-4 md:p-10">
      <aside className="md:w-2/12 md:pr-5 md:sticky">
        <CategorySelector handleTagSelect={handleTagSelect} tags={tags} activeTag={active} />
      </aside>

      <section className="md:w-10/12">
        <CaseList cases={cases} />
      </section>
    </section>
  )
}

export default CaseOverview
