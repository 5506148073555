import React from "react"
import PortableText from "../portableText"
import ImageWithAsideLayout from "../ImageWithAsideLayout"

const TextWithIllustration = ({ title, _rawText, illustration, ...rest }) => {
  if (!_rawText) return null
  return (
    <ImageWithAsideLayout
      image={illustration?.image}
      imageId={illustration?.image?.asset?._id}
      title={title}
    >
      <PortableText blocks={_rawText}></PortableText>
    </ImageWithAsideLayout>
  )
}

export default TextWithIllustration
