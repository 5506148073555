import React, { useEffect } from "react"
import { Link } from "gatsby"
import { imageUrlFor } from "../../lib/image-url"
import { buildImageObj, cn } from "../../lib/helpers"
import LazyImage from "../LazyImage"
import styles from "./caselink.module.css"

function CaseLink({ mainImage, id, slug, title, ...rest }) {
  const aspectRatio = mainImage.aspectRatio
  const { alt } = mainImage
  let image

  if (aspectRatio) {
    image = imageUrlFor(buildImageObj(mainImage))
      .height(250)
      .width(Math.ceil(250 * aspectRatio))
      .auto("format")
      .dpr(window.devicePixelRatio || 2)
      .url()
  } else {
    image = imageUrlFor(buildImageObj(mainImage))
      .height(250)
      .auto("format")
      .dpr(window.devicePixelRatio || 2)
      .url()
  }

  return (
    <div className={cn(styles.caselink)}>
      <Link to={slug.current}>
        <div className="relative">
          <LazyImage alt={alt} src={image} height={250} width="auto" />
          <div className="flex flex-col overflow-hidden ">
            <p className={cn(styles.casetitle, "align-center text-xs absolute")}>
              {mainImage.caption}
            </p>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default CaseLink
