import { motion } from "framer-motion"
import React from "react"
import { cn } from "../../lib/helpers"
import styles from "./category-selector.module.css"

const CategorySelector = ({ activeTag, tags, handleTagSelect }) => {
  const list = {
    visible: {
      opacity: 1,
      transition: {
        damping: 400,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
  }

  const item = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 5 },
  }

  const listClass = "mr-3"

  return (
    <motion.ul
      initial="hidden"
      animate="visible"
      variants={list}
      className="md:p-0 pb-10 flex md:flex-col"
    >
      <motion.li
        variants={item}
        className={cn(styles.categoryBtn, `${listClass} ${activeTag === "all" ? "active" : ""}`)}
      >
        <button onClick={handleTagSelect} data-tag={"all"} className="text-sm">
          All
        </button>
      </motion.li>
      {tags.map((tag) => (
        <motion.li
          variants={item}
          key={tag.id}
          className={cn(
            styles.categoryBtn,
            `${listClass} ${tag.title === activeTag ? "active" : ""}`
          )}
        >
          <button onClick={handleTagSelect} data-tag={tag.title} className="text-sm">
            {tag.title}
          </button>
        </motion.li>
      ))}
    </motion.ul>
  )
}

export default CategorySelector
