import React from "react";
import PortableText from "./portableText";
import CTALink from "./CTALink";
import Figure from "./Figure";

function Hero(props) {

  // const img = maybeImage(props.illustration);
  const images = props.images.map((img) => (
    <div className="w-full">
      <Figure node={img} key={img._key} />
    </div>
  ));
  return (
    <div className="mx-auto flex flex-wrap flex-col md:flex-row items-center">
      {/* Left col */}
      <div className="flex flex-col w-full justify-center items-start text-center md:text-left">
        <p className="uppercase tracking-loose w-full">{props.label}</p>
        <h1 className="my-4 text-5xl font-bold leading-tight">{props.heading}</h1>
        <div className="leading-normal text-2xl mb-8">
          <PortableText blocks={props.tagline} />
        </div>
        {props.cta && props.cta.title && (
          <CTALink
            {...props.cta}
            buttonActionClass="mx-auto ml-4 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg"
          />
        )}

        <>{images}</>
      </div>
    </div>
  );
}

export default Hero;
