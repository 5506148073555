import React from "react"
import { graphql } from "gatsby"

import Hero from "../components/hero"
import CaseOverview from "../containers/CaseOverview"

import GraphQLErrorList from "../components/graphql-error-list"
import SEO from "../components/seo"
import TextWithIllustration from "../components/TextWithIllustration"
import InfoBoxesWithImage from "../components/InfoBoxesWithImage"
import Layout from "../containers/layout"

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      primaryColor {
        hex
      }
      secondaryColor {
        hex
      }
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`

const Page = (props) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const route = data?.route

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const page = data.page || data.route.page

  const content = (page.content || [])
    .filter((c) => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case "hero":
          el = <Hero key={c._key} {...c} />
          break
        case "projectList":
          el = <CaseOverview key={c._key} {...c} />
          break
        case "textWithIllustration":
          el = <TextWithIllustration key={c._key} {...c} />
          break
        case "infoBoxesWithImage":
          el = <InfoBoxesWithImage key={c._key} {...c} />
          break
        default:
          el = null
      }
      return el
    })

  const menuItems = page.navMenu && (page.navMenu.items || [])
  const pageTitle = data.route && !data.route.useSiteTitle && page.title

  return (
    <Layout navMenuItems={menuItems} textWhite={true} location={props.location}>
      <SEO
        title={pageTitle}
        description={route?.openGraph?.description || site.openGraph?.description}
        image={route?.openGraph?.image || site.openGraph?.image}
        keywords={site.keywords}
        bodyAttr={{
          class: "leading-normal tracking-normal",
        }}
      />
      <div className="lg:pt-24 pt-8">{content}</div>
    </Layout>
  )
}

export default Page
