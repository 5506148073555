import React from "react"
import { useInView } from "react-intersection-observer"
import styles from "./lazyimage.module.css"

const LazyImage = ({ width, height, src, ...rest }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "200px 0px",
  })

  const handleLoad = (e) => {
    e.target.style.opacity = 1
  }

  return (
    <div
      key={src}
      ref={ref}
      className={styles.lazyimage}
      style={{
        position: "relative",
        display: "inline-block",
      }}
    >
      {inView ? (
        <img {...rest} onLoad={handleLoad} src={src} width={width} height={height} loading="lazy" />
      ) : null}
    </div>
  )
}

export default LazyImage
