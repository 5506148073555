import React from "react"
import Img from "gatsby-image"
import { getFluidProps } from "../../lib/image-url"
import PortableText from "../portableText"
import ImageWithAsideLayout from "../ImageWithAsideLayout"
import { cn } from "../../lib/helpers"
import styles from "./infoboxes.module.css"

const InfoBoxesWithImage = ({
  title,
  illustration,
  infolist = [],
  socialLink = {},
  socialHeader,
  ...rest
}) => {
  const { socialLinks } = socialLink
  const { infoboxes } = infolist
  const imgProps = getFluidProps(illustration?.image?.asset?._id)
  return (
    <ImageWithAsideLayout title={title} imageId={illustration?.image?.asset?._id}>
      {infoboxes.map(({ __typename, header, ...props }, i) => {
        return (
          <div
            key={i}
            className={cn(styles.infoboxes, `lg:w-1/2 mb-10 lg:pb-10 lg:mb-20 hd:mb-25`)}
          >
            <h6 className="text-gray-800 uppercase text-xs">{header}</h6>
            {getContent(__typename, props)}
          </div>
        )
      })}
      {socialLinks && (
        <div className="flex w-full">
          <h6 className="text-gray-600 uppercase text-sm mr-12">{socialHeader}</h6>
          {socialLinks.map((link) => (
            <a
              href={link.url}
              key={link._key}
              target="_blank"
              className="text-gray-600 text-sm mr-10"
            >
              {link.title}
            </a>
          ))}
        </div>
      )}
    </ImageWithAsideLayout>
  )
}

const TableFields = ({ items }) => (
  <div className="w-full">
    {items.map(({ infokey, infoValue }) => (
      <div key={infokey} className="flex space-between">
        <p className="w-1/2">{infokey}</p>
        <p className="w-1/2">
          <a href={`mailto:${infoValue}`}>{infoValue}</a>
        </p>
      </div>
    ))}
  </div>
)

function getContent(contentType: String, props) {
  console.log(props._rawContent)
  switch (contentType) {
    case "SanityInfobox":
      return <PortableText blocks={props._rawContent}></PortableText>
      break
    case "SanityInfoboxTable":
      const { tableFields } = props
      if (!tableFields) return null
      return <TableFields items={tableFields} />
      break
    default:
      return "not here"
  }
}
export default InfoBoxesWithImage
