import React from "react"
import Img from "gatsby-image"
import { getFluidProps } from "../../lib/image-url"
import { cn } from "../../lib/helpers"

const ImageWithAsideLayout = ({ image, imageId, title, children }) => {
  const imgProps = getFluidProps(imageId)
  return (
    <section className={cn("md:flex p-6 lg:p-10")}>
      <article className={cn("md:w-5/12")}>
        {/* title && <h1 className={"text-4xl font-light"}>{title}</h1> */}
        <div className="lg:flex flex-wrap">{children}</div>
      </article>
      {imgProps && (
        <div className="md:w-6/12 md:order-first">
          <div className="md:w-5/6">
            <Img alt={image.alt} fluid={imgProps} />
          </div>
        </div>
      )}
    </section>
  )
}

export default ImageWithAsideLayout
