import * as React from "react"
import CaseLink from "../CaseLink"
import * as styles from "./caselist.module.css"

function CaseList({ cases }) {
  return (
    <div className={[styles.caselist].join(" ")}>
      {cases &&
        cases.map((p) => {
          return (
            <div className="pb-10 md:pb-20 md:px-5" key={p.id}>
              <CaseLink {...p} />
            </div>
          )
        })}
    </div>
  )
}

export default CaseList
